/* .App {
  text-align: center;
} */
a { 
  cursor: pointer;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

main {
  margin-top: 100px;
  margin-left: 326px;
  margin-right: 24px;
}

.caratpal-textarea {
  height: 100px !important;
}

td,th{
  padding: 5px;
  border: 1px solid gray;
}
td:not(:first-child),th:not(:first-child){
  text-align: center;
}

/* Dropzone CSS */
.image-uploader-zone {
  margin: 30px;
  padding: 30px;
  background: #eee;
  border-radius: 5px;
  border: 1px dashed #ccc;
  text-align: center;
}

.image-uploader-zone p {
  margin: 0;
}

/* Uploads in q */

.images-uploading {
  display: flex;
  padding: 0 20px;
  flex-wrap: wrap;
  
}

.images-uploading .image-upload-preview {
  border: 1px solid #eee;
  border-radius: 4px;
  padding: 10px;
  font-size: 12px;
  margin: 5px;
  width: 30%;
}
.image-upload-preview img ,
.image-upload-preview video{
  max-width: 100%;
  height: auto;
  max-height: 120px;
  width: auto;
}

.delete-image {
  position: absolute;
  top: -8px;
  padding: 0;
  right: 5px;
  font-size: 24px;
  width: 20px;
}

.image-upload-preview {
  position: relative;
}

.css-1s2u09g-control, .css-1pahdxg-control {
  border: none !important;
  margin-top: -8px !important;
  box-shadow: none !important;
}

.css-1pahdxg-control:hover, .css-1pahdxg-control, .css-13cymwt-control{
  border-color: none;
  border: none !important;
  box-shadow: none;
}

.css-1pahdxg-control, .css-13cymwt-control{
  border:none;
  margin-top: -8px !important;
}